


























import TabBase from '@/components/atoms/v3/TabBase.vue'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import StudentHistoryChat from '@/components/organisms/v3/StudentHistoryChat.vue'
import ClassModeChangeAble from '@/mixins/student/ClassModeChangeAble'
import Timelines from '@/mixins/v3/Timelines'
import { Component, Mixins, Ref, Vue } from 'vue-property-decorator'
import { ResponseTimelineChat, TimelineChat, ActionTimelineChat } from '@/types/student'
import QuestionImage from '@/components/modules/drillsv3/molecules/QuestionImage.vue'
import ModalChat from '@/components/modules/drillsv3/organisms/ModalChat.vue'
const LIMIT = 10

@Component({
  components: {
    SidebarSwitcher,
    StudentHistoryChat,
    TabBase,
    QuestionImage,
    ModalChat,
  },
})
export default class HistoryChat extends Mixins(ClassModeChangeAble, Timelines) {
  @Ref() modalChat!: ModalChat
  @Ref() questionImage!: QuestionImage

  private currentRow: TimelineChat | any = {
    subjectCode: '',
    questionImage: {
      d: [],
      j: '',
      m: [],
    },
    createdAt: '',
    pageNum: '',
    questionCode: '',
    curriculumSUnitId: '',
  }
  private page = 1

  private async mounted(): Promise<void> {
    this.optionsObserve = { rootMargin: '200px 0px', root: document.querySelector('.historyChat__list') }
    await this.init(this.loadDatas)
    this.loadDatas()
  }

  private actionRow(row: TimelineChat, action: ActionTimelineChat) {
    this.currentRow = row
    switch (action) {
      case ActionTimelineChat.CHAT:
        this.showModalChat()
        break
      case ActionTimelineChat.SHOW_QUESTION:
        this.showQuestion()
        break
    }
  }

  private showModalChat() {
    setTimeout(() => {
      this.modalChat.showModal()
    })
  }

  private showQuestion() {
    this.questionImage.showImage()
  }

  private async loadDatas() {
    const params: any = {
      page: this.page++,
      per_page: LIMIT,
    }

    await Vue.prototype.$http.httpWithToken
      .get('/v3/question_hints/time_lines', {
        params: params,
      })
      .then((res: any) => {
        const addChats: TimelineChat[] = (res.data as ResponseTimelineChat).histories
        this.timelineData.push(...addChats)
        // 最終行の場合、監視終了
        if (!addChats.length) this.observer.disconnect()
      })
  }
}
