





















import { Component, Prop, Vue, Mixins } from 'vue-property-decorator'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ImageRound from '@/components/atoms/ImageRound.vue'
import LabelBase from '@/components/atoms/LabelBase.vue'
import moment from 'moment'
import DrillWebApi from '@/mixins/drillsv3/WebApi'
import { ActionTimelineChat, TimelineChat } from '@/types/student'
import ModalChat from '@/components/modules/drillsv3/organisms/ModalChat.vue'

@Component({
  components: {
    ColoredBox,
    ImageRound,
    LabelBase,
    ModalChat,
  },
})
export default class StudentHistoryChat extends Mixins(DrillWebApi) {
  @Prop({ default: [] })
  activities!: TimelineChat[]

  @Prop()
  private clickRow!: (row: TimelineChat, action: ActionTimelineChat) => void

  private createdDate(activity: TimelineChat) {
    return moment(activity.createAt).format('YYYY/MM/DD')
  }
  private createdTime(activity: TimelineChat) {
    return moment(activity.createAt).format('HH:mm')
  }
  private clickShowQuestion(activity: TimelineChat) {
    this.clickRow(activity, ActionTimelineChat.SHOW_QUESTION)
  }

  private clickShowChat(activity: TimelineChat) {
    this.clickRow(activity, ActionTimelineChat.CHAT)
  }
}
